const TIPO_TRABALHO = {
    LB: 1,
    LI: 2,
    AMBOS: 3,
    CAP: 4
}

function isConsumerWorkLB(trabalho) {
    return TIPO_TRABALHO.LB == trabalho || TIPO_TRABALHO.AMBOS == trabalho;
}

function isConsumerWorkLI(trabalho) {
    return TIPO_TRABALHO.LI == trabalho || TIPO_TRABALHO.AMBOS == trabalho;
}

function isConsumerWorkCAP(trabalho) {
    return TIPO_TRABALHO.CAP == trabalho;
}

export {
    isConsumerWorkLB,
    isConsumerWorkLI,
    isConsumerWorkCAP
};